<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          v-model="selectedInvoices"
          :headers="headers"
          :items="invoices"
          @item-selected="updateAllocatedPortion"
          show-select
          item-key="invoice_id"
          :search="search"
          :loading="loading"
        >
          <!--Search Bar & Filters-->
          <template v-slot:top>
            <CustomerPaymentsFilters
              @passSearch="search = $event"
              @clearSelection="clearSelection()"
              :selection="selectedInvoices"
            />
          </template>
          <!--Remove "Select All" checkbox-->
          <template v-slot:header.data-table-select> </template>
          <!--Click to view Sales Order-->
          <template v-slot:item.sales_order_ref="{ item }">
            <a @click="viewSalesOrder(item)">{{ item.sales_order_ref }}</a>
          </template>
          <!--Invoice Total-->
          <template v-slot:item.invoice_total="{ item }">{{
            formatAsCurrency("R", item.invoice_total)
          }}</template>
          <!--Invoice Paid-->
          <template v-slot:item.invoice_amount_paid="{ item }">{{
            formatAsCurrency("R", item.invoice_amount_paid)
          }}</template>
          <!--Invoice Due-->
          <template v-slot:item.invoice_amount_due="{ item }">{{
            formatAsCurrency("R", item.invoice_amount_due)
          }}</template>
          <!--Selection checkbox-->
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              :ripple="false"
              @input="select($event)"
              :readonly="amountLeftToAllocate <= 0 && !isSelected"
              :disabled="amountLeftToAllocate <= 0 && !isSelected"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import db from "../../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../../composables/external";
export default {
  name: "CustomerPaymentsInvoiceTable",
  props: ["transaction", "customer", "amountLeftToAllocate"],
  components: {
    CustomerPaymentsFilters: () => import("./CustomerPaymentsFilters.vue"),
  },
  data() {
    return {
      search: "",
      selectedInvoices: [],
      invoices: [],
      loading: false,
      headers: [
        {
          text: "Invoice #",
          value: "invoice_number",
          align: "start",
        },
        { text: "SO #", value: "sales_order_ref" },
        { text: "Customer", value: "customer_name" },
        { text: "Date Issued", value: "invoice_issue_date" },
        { text: "Total", value: "invoice_total", align: "right" },
        { text: "Paid", value: "invoice_amount_paid", align: "right" },
        { text: "Due", value: "invoice_amount_due", align: "right" },
      ],
    };
  },
  computed: {
    //
    invoiceAmountAllocted() {
      return this.selectedInvoices.reduce(
        (total, item) => total + item.allocatedAmount,
        0
      );
    },
  },
  watch: {
    // Get customer invoices when a new customer is selected from dropdown
    customer(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.getInvoices();
    },
    selectedInvoices(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$emit("updateSelection", newValue);
    },
  },
  created() {
    this.getInvoices();
  },
  methods: {
    formatAsCurrency,
    //
    getInvoices() {
      const docRef = db
        .collection("invoices")
        .where("invoice_amount_due", "!=", 0)
        .where("customer.customer_id", "==", this.customer.customer_id);
      docRef.onSnapshot((querySnapshot) => {
        this.invoices = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.invoices.push({
            ...data,
            customer_name: data.customer.customer_name,
            // Ensures invoice amounts can be reverted if an invoice is deselected
            // during the allocation process
            oldAmountPaid: data.invoice_amount_paid,
            oldAmountDue: data.invoice_amount_due,
            allocatedAmount: 0,
          });
        });
      });
    },
    //
    updateAllocatedPortion(item) {
      //  When a payment is selected add item amount due to amount paid
      const calculateNewAmounts = () => {
        const calAllocatedAmount = () => {
          const testQuery =
            this.amountLeftToAllocate >= 0 &&
            item.item.invoice_total <= this.amountLeftToAllocate;

          testQuery
            ? (item.item.allocatedAmount += item.item.oldAmountDue)
            : (item.item.allocatedAmount = this.amountLeftToAllocate);
        };
        calAllocatedAmount();
        item.item.invoice_amount_paid += item.item.allocatedAmount;
        item.item.invoice_amount_due -= item.item.allocatedAmount;
      };
      const revertOldAmounts = () => {
        item.item.invoice_amount_paid = item.item.oldAmountPaid;
        item.item.invoice_amount_due = item.item.oldAmountDue;
        item.item.allocatedAmount = 0;
      };
      return item.value ? calculateNewAmounts() : revertOldAmounts();
    },
    //  Clear selected orders when customer changes
    clearSelection() {
      this.selectedInvoices = [];
      this.$emit("updateSelection", []);
    },
    // Click to view Sales Order in new tab
    viewSalesOrder(item) {
      const routeData = this.$router.resolve({
        path: `/view-order/${item.sales_order_id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>